import { styled as muiStyled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// TODO: create common styles for inputs
export const StyledDatePicker = muiStyled(DatePicker)<{ error?: any }>(({ theme: { colors, palette }, error }) => ({
  width: '100%',
  height: 'auto',

  '& .MuiOutlinedInput-root': {
    height: '40px',
    background: colors.white,

    '& .MuiOutlinedInput-input': {
      boxSizing: 'border-box',

      '&::placeholder': {
        opacity: 1,
        color: palette.text.secondary,
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: error ? palette.error.main : palette.border,
    },

    '&:hover:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.border,
    },

    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },

      '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.primary.main,
      },
    },

    '&.Mui-disabled': {
      background: palette.form.disabled.background,
      color: palette.form.disabled.text,
      '&::placeholder': {
        color: palette.form.disabled.text,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.form.disabled.border,
      },
    },

    '& .MuiIconButton-root': {
      borderRadius: 0,
      backgroundColor: 'transparent',

      '& > svg': {
        width: '18px',
        height: '18px',
      },
    },
  },
}));
