// display
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const BANK_STATEMENT_DATE = 'MM/YYYY';
export const PROFIT_AND_LOSS_DATE = 'YYYY';
export const NOTIFICATION_TIME = 'M/D/YY h:mm A';
export const REMINDER_DATETIME = 'MMMM D YYYY [at] h:mm A';
export const REMINDER_FULL_DATETIME = 'MM/DD/YYYY h:mm A';
export const USER_DATETIME = 'MMMM D, YYYY, h:mm A';

export const FULL_DATE_TIME_WITH_PREPOSITIONS = 'MMMM D, YYYY [at] h:mm A';

export const FROM_DATE_FORMAT = 'MMM D';
export const TO_DATE_FORMAT = 'MMM D YYYY';
export const SHORT_DAY_OF_WEEK = 'ddd';
export const MONTH_DAY = 'D';
export const DAY_MONTH = 'MM/DD';
export const ABBREVIATED_MONTH_NAME = 'MMM';

// store and process
export const ISO_8601_DATE_FORMAT = 'YYYY-MM-DD';
