export const UUID = 'uuid';
export const STEP = 'step';
export const ORIGIN = 'origin';
export const MODE = 'mode';
export const REFERRAL_ID = 'referralId';
export const TOS_URL = 'termsOfServiceUrl';

export const THANK_YOU_PAGE_URL = 'thankYouPageUrl';
export const TERMS_OF_SERVICE_URL = 'termsOfServiceUrl';

export const BRANDED_PAGE = 'brandedPage';
