import type { ConfigType } from 'dayjs';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

// min possible date on prod env at time of adding a new DatePicker
export const DEFAULT_MIN_DATE = dayjs('1000-01-01');
export const DEFAULT_MAX_DATE = dayjs('2099-01-01');
export const AVERAGE_EXPECTED_AGE = dayjs('1985-01-01');
dayjs.extend(isSameOrAfter);

export const isDatePickerValueValid = (value: ConfigType) => {
  const date = dayjs(value);
  return date.isValid() && date.isSameOrAfter(DEFAULT_MIN_DATE, 'day');
};

export const getAge = (isoString: ConfigType) => {
  if (!isDatePickerValueValid(isoString)) return '';
  const now = dayjs();
  const selectedDate = dayjs(isoString);
  // we must exclude future, cause age is a concept before current moment in time
  if (selectedDate.isAfter(now)) {
    return '';
  }
  return selectedDate.fromNow(true);
};
