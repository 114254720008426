import { useSearchParams } from 'react-router-dom';

import { MODE } from 'config/constants/queryParams';

const useCheckIfFrameMode = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get(MODE);

  return { mode, iframeMode: mode === 'iframe' };
};

export default useCheckIfFrameMode;
