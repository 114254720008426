import React from 'react';
import { withTheme } from '@emotion/react';
import type { DatePickerProps as MuiDatePickerProps, PickerValidDate as MuiPickerValidDate } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, ISO_8601_DATE_FORMAT } from 'config/constants/dateTimeFormats';

import OpenPickerIconWrapper from './OpenPickerIconWrapper';
import { StyledDatePicker } from './styled';
import type { DatePickerProps } from './types';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, getAge, isDatePickerValueValid } from './utils';

const DatePicker = (props: DatePickerProps) => {
  const {
    // datepicker props
    value,
    disabled,
    format,
    minDate = DEFAULT_MIN_DATE,
    maxDate = DEFAULT_MAX_DATE,
    onChange,

    // text field props
    inputProps,
    id,
    name,
    placeholder,
    required,
    error,
    onFocus,
    onBlur,

    // custom props
    withAge,
    theme,

    ...restProps
  } = props;

  const handleChange: MuiDatePickerProps<MuiPickerValidDate>['onChange'] = (date) => {
    if (!onChange) return;

    if (date === null) {
      onChange(null);
      return;
    }

    if (isDatePickerValueValid(date)) {
      const formattedDate = date.format(ISO_8601_DATE_FORMAT);
      onChange(formattedDate);
    } else {
      onChange('Invalid Date');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker
        onChange={handleChange}
        value={value ? dayjs(value) : null}
        disabled={disabled}
        format={format}
        minDate={minDate}
        maxDate={maxDate}
        slots={{
          openPickerIcon: OpenPickerIconWrapper,
        }}
        slotProps={{
          openPickerButton: {
            color: 'primary',
            // @ts-expect-error MUI IconButton doesn't have data-testid prop type
            'data-testid': `keyboardPicker-button-${name}`,
          },
          openPickerIcon: {
            'data-testid': `keyboardPicker-icon-${name}`,
            stroke: disabled ? theme?.palette.form.disabled.text : theme?.palette.primary.main,
          },
          textField: {
            required,
            error,
            onFocus,
            onBlur,
            inputProps: {
              id,
              name,
              placeholder: placeholder || format || DEFAULT_DATE_FORMAT,
              ...(inputProps ?? {}),
            },
            helperText: (withAge && getAge(value)) || '',
          },
        }}
        {...restProps}
      />
    </LocalizationProvider>
  );
};

export default withTheme(DatePicker);
