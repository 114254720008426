import { formatToISOStringDateOnly } from 'lib/utils/date';

import type { DatePickerProps } from 'components/ui/atoms/forms/DatePicker';
import DatePicker from 'components/ui/atoms/forms/DatePicker';

type DatePickerMediatorProps = Omit<DatePickerProps, 'onChange' | 'onBlur'> & {
  onChange?: DatePickerProps['onChange'] | ((value: any) => Promise<void>);
  onBlur?: DatePickerProps['onChange'];
};

/**
 * A wrapper around the DatePicker component that modifies some event handlers.
 * It can be used in forms where data needs to be saved when the blur event occurs.
 */
const DateInputMediator = (props: DatePickerMediatorProps) => {
  const { value, onChange, id, name, onBlur, ...other } = props;

  const handleChange = (date: any) => {
    if (!name || !onChange) return;
    onChange({ target: { name, value: date } });
  };

  const handleAccept = async (date: any) => {
    if (!name) return;

    const args = { target: { name, value: formatToISOStringDateOnly(date) } };
    if (onChange) await onChange(args);
    if (onBlur) onBlur(args);
  };

  const handleBlur = () => {
    if (!name || !onBlur) return;
    onBlur({ target: { name, value } });
  };

  return (
    <DatePicker
      value={value}
      onChange={handleChange}
      onAccept={handleAccept}
      id={id}
      name={name ?? id}
      onBlur={handleBlur}
      {...other}
    />
  );
};

export default DateInputMediator;
