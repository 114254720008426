import React from 'react';
import type { SvgIconProps } from '@mui/material';

import iconsConfig, { Icons } from 'config/icons/feather';

type PropsWithoutOwnerState = Omit<SvgIconProps, 'ownerState'> & {
  icon: keyof typeof iconsConfig;
};

/**
 * Helper wrapper to avoid error with providing ownerState (https://github.com/mui/material-ui/issues/32882)
 */
// TODO: [MUI] Try to remove this after upgrading to the latest Material UI version
const OpenPickerIconWrapper = React.forwardRef<SVGSVGElement, PropsWithoutOwnerState>((props, ref) => {
  const { ownerState: _, ...other } = props as any;

  const IconComponent = iconsConfig[Icons.Calendar];

  return IconComponent ? <IconComponent {...other} ref={ref} /> : null;
});

export default OpenPickerIconWrapper;
