import type { FormField } from 'components/shared/organisms/form/FormSection/types';
import FormCheckbox from 'components/ui/atoms/forms/Checkbox/FormCheckbox';
import DateInputMediator from 'components/ui/atoms/forms/DateInputMediator';
import MoneyInput from 'components/ui/atoms/forms/MoneyInput';

import type { CreateRealEstateFormValues } from '../mutationsValues';

export const acquisitionFields: FormField<CreateRealEstateFormValues>[] = [
  {
    id: 'purchasePrice',
    name: 'purchasePrice',
    label: 'Purchase Price ($)',
    control: <MoneyInput />,
  },
  {
    id: 'closeDate',
    name: 'desiredClosureDate',
    label: 'When does it need to close?',
    control: <DateInputMediator />,
  },
  {
    id: 'clientUnderContract',
    name: 'clientUnderContract',
    // @ts-expect-error providing props through form
    control: <FormCheckbox label="Client Under Contract" />,
  },
];
